import React, { useState } from "react";

function ImageOverlay({ imageUrls, initialIndex, onClose }) {

  const [loadedImage, setLoadedImage] = useState(imageUrls[initialIndex]);
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  console.error('what is loaded image')
  console.error(loadedImage)
  console.error('what is current index: ' + currentIndex)

  const onLeftButtonClick = () => {
    const newIndex = currentIndex === 0 ? imageUrls.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    console.error('what is current index: ' + currentIndex)

    setLoadedImage(imageUrls[newIndex]);
  };

  const onRightButtonClick = () => {
    const newIndex = currentIndex === imageUrls.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    console.error('what is current index: ' + currentIndex)

    setLoadedImage(imageUrls[newIndex]);;
  };

  return (
    <div className="overlay-container">
      <div className="overlay-background" onClick={onClose} />
      <div className="overlay-content">
        <img
          src={loadedImage.watermarked}
          // src={loadedImage}
          alt={`${currentIndex + 1}`}
          onLoad={() => setLoadedImage(imageUrls[currentIndex])}
          style={{ maxWidth: "90vw", maxHeight: "90vh" }}

        />
        <div className="overlay-buttons">
          <button onClick={onLeftButtonClick}>{"<"}</button>
          <button onClick={onRightButtonClick}>{">"}</button>
        </div>
      </div>
    </div>
  );
}

export default ImageOverlay;
