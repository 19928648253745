import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ThumbnailGrid.css';
import NavBarMain from './NavBarMain';
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getFirestore, collection, query, getDocs } from "firebase/firestore";


function ThumbnailGrid() {
  const navigate = useNavigate();
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    setGalleryData([])
    const firebaseConfig = {
      storageBucket: "edelfall-f.appspot.com",
      projectId: "edelfall-f"
    };
    const app = initializeApp(firebaseConfig);
    
    async function getGalleryDocuments() {
      const app = initializeApp(firebaseConfig);

      const db = getFirestore(app);
      const galleriesRef = collection(db, 'galleries');
      const q = query(galleriesRef);
      const querySnapshot = await getDocs(q)

      const docs = querySnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          docId: doc.id,
          model: docData.model,
          galleryName: docData.gallery,
          order: docData.order,
          thumbnail: docData.thumbnail
        }
      })
      return docs
    }

    async function getGalleryThumbnails (docs) {
      const storage = getStorage(app)
      
      for (let i = 0; i < docs.length; i++) {
        const galleryDoc = docs[i]
        const galleryName = galleryDoc.galleryName
        const imageRef = ref(storage, `galleries/${galleryName}/thumbnails/${galleryDoc.thumbnail}`);
        getDownloadURL(imageRef).then((url) => {
          const data = {
            url,
            ...galleryDoc
          }
          setGalleryData((prevArray) => {
            const newArray = [...prevArray, data]
            newArray.sort((a, b) => b.order - a.order);
            return newArray;
          })
        });
      }
    }
    
    getGalleryDocuments().then((docs) => {
      getGalleryThumbnails(docs);
    })
  }, []);

  function handleClick(model) {
    navigate(`/model/${model.docId}`);
  }

  return (
    <div>
      <NavBarMain></NavBarMain>
      <div className="thumbnail-grid">
        {galleryData.map((data, index) => (
          <div
            key={data.galleryName}
            className="thumbnail"
            onClick={() => handleClick(data)}
          >
            <img
              src={data.url}
              alt={`${index}`}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />          
          </div>
        ))}
      </div>

    </div>
  );
}

export default ThumbnailGrid;
