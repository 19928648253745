import React, { useState, useEffect }  from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ModelGallery.css';
import './ThumbnailGrid.css';
import ImageOverlay from "./ImageOverlay";
import NavBarGallery from './NavBarGallery';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

function ModelGallery() {
  const { docId } = useParams();
  const [modelData, setModelData] = React.useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayIndex, setOverlayIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);

  useNavigate();
  console.error(docId)
  
  useEffect(() => {
    const firebaseConfig = {
      storageBucket: "edelfall-f.appspot.com",
      projectId: "edelfall-f"
    };

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const storage = getStorage();

    async function getGalleryDocument() {

      const docRef = doc(db, "galleries", docId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        const docData = docSnap.data();
        setModelData(docData);
        return docData
      }
    }

    async function getImages(docData) {
      const listRef = ref(storage, `galleries/${docData.gallery}/thumbnails`);
      await listAll(listRef)
        .then((res) => {
          for(let i =0; i < res.items.length; i++) {
            const itemRef = res.items[i];
            console.error(itemRef)
              getDownloadURL(itemRef).then((url) => {
                const watermarkedName = url.replace('thumbnails', 'watermarked');
                const urlData = {
                  thumbnail: url,
                  watermarked: watermarkedName,
                  order: i
                }
                setImageUrls((prevArray) => {
                  const newArray = [...prevArray, urlData]
                  newArray.sort((a, b) => a.order - b.order);
                  return newArray;
                })
              })
          }
        });
    }
 
    getGalleryDocument()
      .then((data) => getImages(data))
  }, [docId]);

  const toggleOverlay = (index) => {

    setShowOverlay(!showOverlay);
    setShowOverlay(true)
    setOverlayIndex(index);
  };

  const onCloseOverlay = () => {
    setShowOverlay(false);
    setOverlayIndex(0);
  };

  const renderImages = () => {
    return (
      <div className="modelgallery-grid">
        {imageUrls.map((imageData, index) => (
          <div
            key={modelData.gallery}
            className="modelgallery"
          >
            <img
              key={index}
              src={imageData.thumbnail}
              className="modelgallery"
              alt={`${index}`}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              onClick={() => toggleOverlay(index)}
            />
          </div>
        ))}
      </div>
    ) 
  }

  if (!modelData) {
    return <div>Loading...</div>;
  }
  const { model } = modelData;

  return (
    <div>
      <NavBarGallery></NavBarGallery>
      {/* <button onClick={() => window.history.back()}>Back</button> */}
      <h1 className="modelgallery-name" style={{ 'font-family': "Tilt Warp" }}>{model}</h1>
      <div> {renderImages()} </div>
      {showOverlay && (
        <ImageOverlay
          imageUrls={imageUrls}
          initialIndex={overlayIndex}
          onClose={onCloseOverlay}
        />
      )}
    </div>
  );
}

export default ModelGallery;
