import React from 'react';
import ThumbnailGrid from './ThumbnailGrid';
import ModelGallery from './ModelGallery';

import { Routes, Route, BrowserRouter } from 'react-router-dom';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// import ReactGA from "react-ga4";
// ReactGA.initialize("G-D4H3V2EW0E");


// require("https://cdn.jsdelivr.net/npm/purecss@3.0.0/build/pure-min.css")
function App() {
  return (
    <div className="App">
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
      </head>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ThumbnailGrid />} />
          <Route path="/model/:docId" element={<ModelGallery />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
